<template>
  <TagbyOverlay :show="state.isLoading">
    <BRow>
      <BCol cols="4">
        <BCard>
          <div class="d-flex justify-content-between mb-1">
            <div
              style="font-size: 1.2rem"
            >
              완료: {{ state.resultCount }}
            </div>
            <div>
              <BButton
                variant="outline-primary"
                :disabled="!getters.isValid"
                @click="updateResult"
              >
                다음
              </BButton>
            </div>
          </div>
          <BImg
            :src="getters.displayUrl"
            fluid
          />
          <div style="font-size: 1.2rem">
            {{ getters.caption }}
          </div>
        </BCard>
      </BCol>

      <BCol cols="8">
        <BCard>
          <BRow>
            <BCol>

              <BFormGroup>
                <template #label>
                  <span class="tg-header">
                    카테고리
                  </span>
                </template>
                <MultipleRadioGroup
                  v-model="state.category"
                  :multipleOptions="categoryOptions"
                />
              </BFormGroup>
            </BCol>
          </BRow>
          <BRow>
            <BCol cols="6">
              <BFormGroup>
                <template #label>
                  <span class="tg-header">
                    나이대
                  </span>
                </template>
                <BFormRadioGroup
                  ref="ageRef"
                  v-model="state.age"
                  :options="ageOptions"
                  @input="focusAge"
                />
              </BFormGroup>
            </BCol>
            <BCol cols="6">
              <BFormGroup>
                <template #label>
                  <span class="tg-header">
                    성별
                  </span>
                </template>
                <BFormRadioGroup
                  v-model="state.gender"
                  :options="genderOptions"
                  @input="focusGender"
                />
              </BFormGroup>
            </BCol>
          </BRow>
        </BCard>
      </BCol>
    </BRow>
  </TagbyOverlay>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BFormGroup,
  BFormRadioGroup,
  BButton,
  BTabs,
} from 'bootstrap-vue'
import MultipleRadioGroup from './components/MultipleRadioGroup.vue'
import SelectGroup from './components/SelectGroup.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import state from './state'
import getters from './getters'
import useFetch from './useFetch'
import useOptions from './useOptions'
import useUpdateResult from './useUpdateResult'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormGroup,
    BFormRadioGroup,
    MultipleRadioGroup,
    SelectGroup,
    BButton,
    BTabs,
    TagbyOverlay,
  },
  setup() {
    const {
      updateResult,
    } = useUpdateResult()

    const scrollToTop = () => {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        // behavior: 'smooth',
      })
    }

    const {
      getTargetList,
      getResultCount,
    } = useFetch()

    const takeNextTarget = () => {
      const isSuccess = updateResult()
      if (isSuccess) {
        scrollToTop()
      }
      if (state.targetList.length < 20) {
        getTargetList()
      }
    }

    const {
      ageOptions,
      categoryOptions,
      genderOptions,
      onKeydownFactory,
      onKeyUp,
    } = useOptions()

    const onKeydown = onKeydownFactory(takeNextTarget)

    onMounted(() => {
      document.addEventListener('keydown', onKeydown)
      // document.addEventListener('keyup', onKeyUp)
      getTargetList()
      getResultCount()
    })

    const ageRef = ref()
    const genderRef = ref()

    const focusAge = () => {
      if (ageRef.value) {
        ageRef.value.$el.focus()
      }
    }
    const focusGender = () => {
      if (genderRef.value) {
        genderRef.value.$el.focus()
      }
    }

    return {
      state,
      getters,

      ageOptions,
      categoryOptions,
      genderOptions,

      ageRef,
      genderRef,
      focusAge,
      focusGender,

      updateResult,
    }
  },
})
</script>

<style scoped>
.tg-header {
  font-size: 1.3rem;
}
</style>
