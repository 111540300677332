import {
  computed,
  reactive,
} from '@vue/composition-api'

import state from './state'

const currentTarget = computed(() => state.targetList?.[0])
const displayUrl = computed(() => currentTarget.value?.img_url)
const caption = computed(() => currentTarget.value?.caption)

const isExactlyPerson = computed(() => Boolean(state.gender) && Boolean(state.age))
const isExactlyNotPerson = computed(() => !state.gender && !state.age)

const isValid = computed(() => {
  if (state.isLoading) {
    return false
  }
  return Boolean(state.category) && (isExactlyPerson.value || isExactlyNotPerson.value)
})

export default reactive({
  displayUrl,
  caption,
  isExactlyPerson,
  isValid,
})
