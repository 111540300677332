<template>
  <div class="d-flex flex-wrap">
    <!-- <BFormGroup
      v-for="(options, category) in multipleOptions"
      :key="category"
      label-cols="1"
      content-cols="11"
    >
      <template #label>
        <span class="tg-label">
          {{ category }}
        </span>
      </template>
      <BFormRadioGroup
        :options="options"
        name="category"
        @input="$emit('input', [category, $event])"
      />
    </BFormGroup> -->

    <SelectButton
      v-for="[category, option] in reducedOptions"
      :key="(category, option)"
      :value="`(${category})${option}`"
      :checked="false"
      class="tg-gap"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
} from '@vue/composition-api'
import {
  BFormGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import SelectButton from './SelectButton.vue'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input',
  },
  emits: [
    'input',
  ],
  components: {
    BFormGroup,
    BFormRadioGroup,
    SelectButton,
  },
  props: {
    value: {
      type: Array,
    },
    multipleOptions: {
      type: Object,
    },
  },
  setup(props) {
    const reducedOptions = computed(() => {
      if (props.multipleOptions == null) {
        return []
      }
      const categoryAndOptions = Object.entries(props.multipleOptions)
      const optionsWithCategory = categoryAndOptions.map(([category, options]) => options.map(option => [category, option]))
      const reducedResults = optionsWithCategory.reduce((p, c) => [...p, ...c], [])
      return reducedResults
    })

    return {
      reducedOptions,
    }
  },
})
</script>

<style scoped>
.tg-label {
  font-size: 1rem;
}
.tg-gap {
  gap: 10px
}
</style>
