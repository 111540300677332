import api from './api'
import state from './state'

export default () => {
  const getTargetList = () => {
    state.isLoading = true
    return api.getTargetList().then(response => {
      state.targetList = response.data.data
      state.isLoading = false
    })
  }

  const getResultCount = () => api.getResultCount().then(response => {
    state.resultCount = response.data.data.result_count
  })

  return {
    getTargetList,
    getResultCount,
  }
}
