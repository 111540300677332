<template>
  <BButton
    pill
    :variant="checked ? 'primary' : 'outline-primary'"
  >
    {{ value }}
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BButton,
  },
  props: {
    checked: {
      type: Boolean,
    },
    value: {
      type: String,
    },
  },
  setup() {

  },
})
</script>
