import {
  ref,
} from '@vue/composition-api'

import state from './state'

export default () => {
  const categoryOptions = ref([
    {
      label: '뷰티(Q)',
      value: '뷰티',
      options: [
        { label: '스킨케어(U)', value: '스킨케어' },
        { label: '색조/베이스(I)', value: '색조/베이스' },
        { label: '헤어(O)', value: '헤어' },
        { label: '네일(P)', value: '네일' },
        { label: '기타(J)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '패션(W)',
      value: '패션',
      options: [
        { label: '캐주얼(U)', value: '캐주얼' },
        { label: '스트릿(I)', value: '스트릿' },
        { label: '클래식(O)', value: '클래식' },
        { label: '기타(P)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '스포츠(E)',
      value: '스포츠',
      options: [
        { label: '테니스(U)', value: '테니스' },
        { label: '골프(I)', value: '골프' },
        { label: '등산/클라이밍(O)', value: '등산/클라이밍' },
        { label: '헬스/크로스핏(P)', value: '헬스/크로스핏' },
        { label: '수영/수상레포츠(J)', value: '수영/수상레포츠' },
        { label: '요가필라테스/홈트레이닝(K)', value: '요가필라테스/홈트레이닝' },
        { label: '러닝/사이클(L)', value: '러닝/사이클' },
        { label: '기타(;)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: 'IT게임(R)',
      value: 'IT게임',
      options: [
        { label: '모바일/PC(U)', value: '모바일/PC' },
        { label: '게임(I)', value: '게임' },
        { label: '기타(O)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '펫(A)',
      value: '펫',
      options: [
        { label: '개(U)', value: '개' },
        { label: '고양이(I)', value: '고양이' },
        { label: '기타(O)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '여행(S)',
      value: '여행',
      options: [
        { label: '국내(U)', value: '국내' },
        { label: '해외(I)', value: '해외' },
        { label: '캠핑/글램핑(O)', value: '캠핑/글램핑' },
        { label: '기타(P)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '문화(D)',
      value: '문화',
      options: [
        { label: '전시공연(U)', value: '전시공연' },
        { label: '도서영화(I)', value: '도서영화' },
        { label: '사진영상(O)', value: '사진영상' },
        { label: '웹툰(P)', value: '웹툰' },
        { label: '기타(J)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '푸드(F)',
      value: '푸드',
      options: [
        { label: '맛집(U)', value: '맛집' },
        { label: '카페/디저트(I)', value: '카페/디저트' },
        { label: '레시피(O)', value: '레시피' },
        { label: '기타(P)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '육아(Z)',
      value: '육아',
      options: [
        { label: '출산임신(U)', value: '출산임신' },
        { label: '영유아(I)', value: '영유아' },
        { label: '초등학생(O)', value: '초등학생' },
        { label: '중고등학생(P)', value: '중고등학생' },
        { label: '기타(J)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '홈(X)',
      value: '홈',
      options: [
        { label: '살림(U)', value: '살림' },
        { label: '인테리어(I)', value: '인테리어' },
        { label: '가전가구(O)', value: '가전가구' },
        { label: '기타(P)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '일상(C)',
      value: '일상',
      options: [
        { label: '기타(U)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '기타(V)',
      value: '기타',
      options: [
        { label: '기타(U)', value: '기타' },
      ],
      disabled: false,
    },
    {
      label: '알 수 없음(B)',
      value: '알 수 없음',
      options: [
        { label: '알 수 없음(U)', value: '알 수 없음' },
      ],
      disabled: false,
    },
  ])

  const genderOptions = ['남성', '여성']
  const ageOptions = ['아이', '학생', '청년', '중장년', '노인']

  const firstKey = ref()

  const toggleCategoryOptions = (i, key) => {
    const temp = categoryOptions.value
    console.log(i)
    console.log(key)
    console.log(firstKey.value)
    if (i != null) {
      if (firstKey.value == null || firstKey.value !== key) {
        temp.forEach(e => { e.disabled = true })
        temp[i].disabled = false
        firstKey.value = key
      } else {
        console.log('false')
        temp.forEach(e => { e.disabled = false })
        firstKey.value = null
      }
    } else {
      temp.forEach(e => { e.disabled = false })
    }
    categoryOptions.value = temp
  }

  const onKeydownFactory = nextCallback => {
    const onKeydown = event => {
      if (event.code === 'KeyQ') {
        toggleCategoryOptions(0, 'Q')
      } else if (event.code === 'KeyW') {
        toggleCategoryOptions(1, 'W')
      } else if (event.code === 'KeyE') {
        toggleCategoryOptions(2, 'E')
      } else if (event.code === 'KeyR') {
        toggleCategoryOptions(3, 'R')
      } else if (event.code === 'KeyA') {
        toggleCategoryOptions(4, 'A')
      } else if (event.code === 'KeyS') {
        toggleCategoryOptions(5, 'S')
      } else if (event.code === 'KeyD') {
        toggleCategoryOptions(6, 'D')
      } else if (event.code === 'KeyF') {
        toggleCategoryOptions(7, 'F')
      } else if (event.code === 'KeyZ') {
        toggleCategoryOptions(8, 'Z')
      } else if (event.code === 'KeyX') {
        toggleCategoryOptions(9, 'X')
      } else if (event.code === 'KeyC') {
        toggleCategoryOptions(10, 'C')
      } else if (event.code === 'KeyV') {
        toggleCategoryOptions(11, 'V')
      } else if (event.code === 'KeyB') {
        toggleCategoryOptions(12, 'B')
      } else if (event.code === 'KeyU') {
        if (firstKey.value === 'Q') {
          state.category = ['뷰티', '스킨케어']
        } else if (firstKey.value === 'W') {
          state.category = ['패션', '캐주얼']
        } else if (firstKey.value === 'E') {
          state.category = ['스포츠', '테니스']
        } else if (firstKey.value === 'R') {
          state.category = ['IT게임', '모바일/PC']
        } else if (firstKey.value === 'A') {
          state.category = ['펫', '개']
        } else if (firstKey.value === 'S') {
          state.category = ['여행', '국내']
        } else if (firstKey.value === 'D') {
          state.category = ['문화', '전시공연']
        } else if (firstKey.value === 'F') {
          state.category = ['푸드', '맛집']
        } else if (firstKey.value === 'Z') {
          state.category = ['육아', '출산임신']
        } else if (firstKey.value === 'X') {
          state.category = ['홈', '살림']
        } else if (firstKey.value === 'C') {
          state.category = ['일상', '기타']
        } else if (firstKey.value === 'V') {
          state.category = ['기타', '기타']
        } else if (firstKey.value === 'B') {
          state.category = ['알 수 없음', '알 수 없음']
        }
      } else if (event.code === 'KeyI') {
        if (firstKey.value === 'Q') {
          state.category = ['뷰티', '색조/베이스']
        } else if (firstKey.value === 'W') {
          state.category = ['패션', '스트릿']
        } else if (firstKey.value === 'E') {
          state.category = ['스포츠', '골프']
        } else if (firstKey.value === 'R') {
          state.category = ['IT게임', '게임']
        } else if (firstKey.value === 'A') {
          state.category = ['펫', '고양이']
        } else if (firstKey.value === 'S') {
          state.category = ['여행', '해외']
        } else if (firstKey.value === 'D') {
          state.category = ['문화', '도서영화']
        } else if (firstKey.value === 'F') {
          state.category = ['푸드', '카페/디저트']
        } else if (firstKey.value === 'Z') {
          state.category = ['육아', '영유아']
        } else if (firstKey.value === 'X') {
          state.category = ['홈', '인테리어']
        }
      } else if (event.code === 'KeyO') {
        if (firstKey.value === 'Q') {
          state.category = ['뷰티', '헤어']
        } else if (firstKey.value === 'W') {
          state.category = ['패션', '클래식']
        } else if (firstKey.value === 'E') {
          state.category = ['스포츠', '등산/클라이밍']
        } else if (firstKey.value === 'R') {
          state.category = ['IT게임', '기타']
        } else if (firstKey.value === 'A') {
          state.category = ['펫', '기타']
        } else if (firstKey.value === 'S') {
          state.category = ['여행', '캠핑/글램핑']
        } else if (firstKey.value === 'D') {
          state.category = ['문화', '사진영상']
        } else if (firstKey.value === 'F') {
          state.category = ['푸드', '레시피']
        } else if (firstKey.value === 'Z') {
          state.category = ['육아', '초등학생']
        } else if (firstKey.value === 'X') {
          state.category = ['홈', '가전가구']
        }
      } else if (event.code === 'KeyP') {
        if (firstKey.value === 'Q') {
          state.category = ['뷰티', '네일']
        } else if (firstKey.value === 'W') {
          state.category = ['패션', '기타']
        } else if (firstKey.value === 'E') {
          state.category = ['스포츠', '헬스/크로스핏']
        } else if (firstKey.value === 'S') {
          state.category = ['여행', '기타']
        } else if (firstKey.value === 'D') {
          state.category = ['문화', '웹툰']
        } else if (firstKey.value === 'F') {
          state.category = ['푸드', '기타']
        } else if (firstKey.value === 'Z') {
          state.category = ['육아', '중고등학생']
        } else if (firstKey.value === 'X') {
          state.category = ['홈', '기타']
        }
      } else if (event.code === 'KeyJ') {
        if (firstKey.value === 'Q') {
          state.category = ['뷰티', '기타']
        } else if (firstKey.value === 'E') {
          state.category = ['스포츠', '수영/수상레포츠']
        } else if (firstKey.value === 'D') {
          state.category = ['문화', '기타']
        } else if (firstKey.value === 'Z') {
          state.category = ['육아', '기타']
        }
      } else if (event.code === 'KeyK') {
        if (firstKey.value === 'E') {
          state.category = ['스포츠', '요가필라테스/홈트레이닝']
        }
      } else if (event.code === 'KeyL') {
        if (firstKey.value === 'E') {
          state.category = ['스포츠', '러닝/사이클']
        }
      } else if (event.code === 'Semicolon') {
        if (firstKey.value === 'E') {
          state.category = ['스포츠', '기타']
        }
      } else if (event.code === 'Digit1') {
        state.age = '아이'
      } else if (event.code === 'Digit2') {
        state.age = '학생'
      } else if (event.code === 'Digit3') {
        state.age = '청년'
      } else if (event.code === 'Digit4') {
        state.age = '중장년'
      } else if (event.code === 'Digit5') {
        state.age = '노인'
      } else if (event.code === 'Digit9') {
        state.gender = '남성'
      } else if (event.code === 'Digit0') {
        state.gender = '여성'
      } else if (event.code === 'Backquote') {
        state.gender = null
        state.age = null
      } else if (event.code === 'Space') {
        event.preventDefault()
        nextCallback()
      }
    }
    return onKeydown
  }
  const onKeyUp = event => {
    const k = event.code
    if ([
      'KeyQ', 'KeyW', 'KeyE', 'KeyR',
      'KeyA', 'KeyS', 'KeyD', 'KeyF',
      'KeyZ', 'KeyX', 'KeyC', 'KeyV',
      'KeyB',
    ].includes(k)) {
      toggleCategoryOptions()
    }
  }

  return {
    categoryOptions,
    genderOptions,
    ageOptions,

    onKeydownFactory,
    onKeyUp,
  }
}
