import api from './api'
import getters from './getters'
import state from './state'
import useFetch from './useFetch'
import useToast from '@/utils/toast'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getTargetList,
    getResultCount,
  } = useFetch()

  const updateResult = () => {
    if (!getters.isValid) {
      return false
    }
    const target = state.targetList[0]
    state.resultCount += 1
    api.updateResult({
      result_idx: target.idx,
      level1_category: state.category[0],
      level2_category: state.category[1],
      is_person: getters.isExactlyPerson,
      age_group: state.age,
      gender: state.gender,
    }).then(() => {
      if (state.resultCount % 10 === 0) {
        getResultCount()
      }
      if (state.targetList.length === 1) {
        getTargetList()
      }
      state.targetList.shift()
    }).catch(err => {
      if (err?.response?.data?.code === 100) {
        makeToast('danger', '로그인 정보가 만료됐습니다. 새로고침 해주세요.')
      } else {
        makeToast('danger', '저장에 실패했습니다. 관리자에게 문의주세요.')
      }
      state.resultCount -= 1
    })
    state.category = null
    state.gender = null
    state.age = null
    return true
  }

  return {
    updateResult,
  }
}
