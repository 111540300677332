<template>
  <div>
    <BFormGroup
      v-for="(category, i) in multipleOptions"
      :key="category.value"
      label-cols="2"
      content-cols="10"
      :disabled="category.disabled"
    >
      <template #label>
        <span class="tg-label">
          {{ category.label }}
        </span>
      </template>
      <template #default>
        <div class="d-flex flex-wrap align-items-center h-100">
          <BFormRadio
            v-for="(option, j) in category.options"
            :key="2*i + 3*j"
            class="mr-1"
            :checked="[category.value, option.value]"
            name="category"
            :value="value"
            @input="$emit('input', [category.value, option.value])"
          >
            {{ option.label }}
          </BFormRadio>
        </div>
      </template>
    </BFormGroup>
  </div>
</template>

<script>
import {
  defineComponent,
} from '@vue/composition-api'
import {
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input',
  },
  emits: [
    'input',
  ],
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    value: {
      type: Array,
    },
    multipleOptions: {
      type: Array,
    },
  },
  setup() {
  },
})
</script>

<style scoped>
.tg-label {
  font-size: 1rem;
}
</style>
